import { IPage } from "Components/Pages/MarketingSite/Page/types";
import { Entry } from "contentful";
import { createContext } from "react";
import { PageComponent } from "Types/contentful";

export const ContentfulPagesContext = createContext<Entry<IPage>[] | undefined>(
  [],
);

export const ContentfulComponentContext = createContext<
  Entry<PageComponent> | undefined
>(undefined);
