import styled from "@emotion/styled/macro";
import { HEX } from "Components/Quarks/Colors";

export const StyledLoader = styled.div<{
  isError?: boolean;
  isFullScreen: boolean;
}>`
  width: 100%;
  height: 100vh;
  background-color: ${(props) =>
    props.isError
      ? HEX.turkey
      : props.isFullScreen
      ? HEX.lightblue
      : "transparent"};
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${(props) => (props.isFullScreen ? "absolute" : "relative")};
  top: 0;
  z-index: 999999999;
  color: ${HEX.white};
  text-align: center;
  overflow: hidden;
`;
