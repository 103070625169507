import { useContext } from "react";
import AnalyticsContext from "Contexts/AnalyticsContext";

export const useAnalytics = () => {
  const result = useContext(AnalyticsContext);
  if (!result) {
    throw new Error("Context used outside of its Provider!");
  }
  return result;
};
