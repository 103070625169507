import React, { Suspense } from "react";
import Loader from "Components/Organisms/Loader";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ToastProvider from "Providers/ToastProvider";
import Toast from "Components/Atoms/Toast";
import { ScrollToTop } from "Utils/ScrollToTop";
import MyProvider from "Providers/Provider";
import LocationListener from "./Components/Atoms/LocationListener";
import { useExperiments } from "@smalls/helpers";

const MarketingSiteRoutes = React.lazy(() => {
  return import("Components/Pages/MarketingSite");
});

const AccountPortalRoutes = React.lazy(() => {
  return import("Components/Pages/AccountPortal");
});

const suspenseFallback = <Loader />;

function App(): JSX.Element {
  console.log("😻");

  useExperiments();

  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback={suspenseFallback}>
        <MyProvider>
          <ToastProvider>
            <LocationListener>
              <Routes>
                <Route path="/*" element={<MarketingSiteRoutes />} />
                <Route path="/account/*" element={<AccountPortalRoutes />} />
              </Routes>
            </LocationListener>
            <Toast />
          </ToastProvider>
        </MyProvider>
      </Suspense>
    </Router>
  );
}

export default App;
