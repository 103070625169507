export const ATF_CONTENT_TYPE = "atf";
export const CENTERALIGNEDTEXT_CONTENT_TYPE = "centerAlignedText";
export const COMPARISON_CONTENT_TYPE = "comparison";
export const FOOTER_CONTENT_TYPE = "footer";
export const LINK_GROUP = "linkGroup";
export const NAV_CONTENT_TYPE = "navigationBar";
export const PAGE_CONTENT_TYPE = "page";
export const SCROLLING_TEXT = "scrollingText";
export const THREE_UP_CONTENT_TYPE = "threeUp";
export const THREE_UP_ICONS_CONTENT_TYPE = "threeUpIcons";
export const TIMELINE_CONTENT_TYPE = "timeline";
export const SIXTYFORTY_CONTENT_TYPE = "sixtyForty";
export const PRESS_CONTENT_TYPE = "press";
export const FIFTYFIFTY_CONTENT_TYPE = "fiftyFifty";
export const FULLWIDTHMEDIA_CONTENT_TYPE = "fullWidthMedia";
export const TWO_UP_CONTENT_TYPE = "twoUp";
export const MONEY_BACK_GUARANTEE_CONTENT_TYPE = "moneyBackGuarantee";
export const MARKDOWN_CONTENT_TYPE = "markdown";
