import React, { Fragment } from "react";
import { ReactComponent as Spinning } from "Images/spinning.svg";
import { StyledLoader } from "./styles/loader.scss";

type ILoader = {
  error?: boolean;
  label?: React.SetStateAction<string>;
  isFullScreen?: boolean;
};

const Loader: React.FC<ILoader> = ({
  error,
  label,
  isFullScreen = true,
}: ILoader) => {
  return (
    <StyledLoader isError={error} isFullScreen={isFullScreen}>
      <div>
        {error ? (
          <Fragment>
            {label ? (
              <h1>{label}</h1>
            ) : (
              <Fragment>
                <h1>There was an error</h1>
                <h6>Please try again later</h6>
              </Fragment>
            )}
          </Fragment>
        ) : (
          <Spinning className="h-auto w-16 animate-spin" />
        )}
      </div>
    </StyledLoader>
  );
};

export default Loader;
