export const BEEF = "beef";
export const BEIGE = "beige";
export const DUCK = "duck";
export const FISH = "fish";
export const GREIGE = "greige";
export const LIGHT_BLUE = "lightblue";
export const SAGE = "sage";
export const TURKEY = "turkey";
export const WARM_GREY = "warmgrey";
export const DARK_GREY = "darkgrey";
export const WARM_GREY50 = "warmgrey50";
export const WARM_GREY80 = "warmgrey80";
export const WARM_GREY90 = "warmgrey90";
export const WHITE = "white";
export const YELLOW = "yellow";
export const BLACK = "black";
export const BLACK72 = "black72";
export const BLACK8 = "black8";
export const BLACK7 = "black7";
export const BLACK6 = "black6";
export const DARKGREY = "darkgrey";
export const RED = "red";
export const TRANSPARENT = "transparent";

export const HEX: Record<string, string> = {
  [BEEF]: "#f8a98d",
  [BEIGE]: "#c89f84",
  [DUCK]: "#c5d6a5",
  [FISH]: "#8caad0",
  [GREIGE]: "#e1d9c9",
  [LIGHT_BLUE]: "#c5d4e9",
  [SAGE]: "#96a9a5",
  [TURKEY]: "#f8a06a",
  [WARM_GREY]: "#f0ece5",
  [DARK_GREY]: "#808080",
  [WARM_GREY50]: "#f7f5f1",
  [WARM_GREY80]: "#faf8f3",
  [WARM_GREY90]: "#E5E5E5",
  [WHITE]: "#ffffff",
  [YELLOW]: "#ffe95a",
  [BLACK]: "#000",
  [BLACK72]: "#434240",
  [BLACK8]: "#DDD9D3", // neutral 200
  [BLACK7]: "#A49F97", // neutral 400
  [BLACK6]: "#cac6c0",
  [DARKGREY]: "#808080",
  [RED]: "#af2a0c",
  [TRANSPARENT]: "transparent",
} as const;

export type Colors =
  | typeof BEEF
  | typeof BEIGE
  | typeof DUCK
  | typeof FISH
  | typeof GREIGE
  | typeof LIGHT_BLUE
  | typeof SAGE
  | typeof TURKEY
  | typeof WARM_GREY
  | typeof DARK_GREY
  | typeof WARM_GREY50
  | typeof WARM_GREY80
  | typeof WARM_GREY90
  | typeof WHITE
  | typeof YELLOW
  | typeof BLACK
  | typeof BLACK72
  | typeof BLACK8
  | typeof BLACK7
  | typeof BLACK6
  | typeof DARKGREY
  | typeof RED
  | typeof TRANSPARENT;
