import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { OverlayProvider } from "@react-aria/overlays";
import reportWebVitals from "./reportWebVitals";
import AnalyticsProvider from "Providers/AnalyticsProvider";

if (process.env.REACT_APP_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
  });
}

const ErrorBoundaryPlugin = Bugsnag.getPlugin("react");
const ErrorBoundary = ErrorBoundaryPlugin?.createErrorBoundary(React);

const AppWithProvider = () => (
  <React.StrictMode>
    <HelmetProvider>
      <OverlayProvider>
        <AnalyticsProvider>
          <App />
        </AnalyticsProvider>
      </OverlayProvider>
    </HelmetProvider>
  </React.StrictMode>
);

ReactDOM.render(
  <>
    {!ErrorBoundary ? (
      <AppWithProvider />
    ) : (
      <ErrorBoundary>
        <AppWithProvider />
      </ErrorBoundary>
    )}
  </>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
