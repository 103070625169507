import React, { createContext, useState, useMemo, SetStateAction } from "react";

import { ICustomer } from "Types/AccountPortal";

export type CurrentCustomerContextProps = {
  currentCustomer?: ICustomer;
  setCurrentCustomer: React.Dispatch<
    React.SetStateAction<ICustomer | undefined>
  >;
};

const customerKey = "smalls-logged-in-customer";
const getSavedCustomer = () => {
  const item = localStorage.getItem(customerKey);
  if (item) {
    try {
      const customer = JSON.parse(item) as ICustomer;
      return customer;
    } catch (error) {
      return undefined;
    }
  }
};

const CurrentCustomerContext = createContext<CurrentCustomerContextProps>({
  currentCustomer: getSavedCustomer(),
  setCurrentCustomer: () => {},
});

export default CurrentCustomerContext;

export const CurrentCustomerProvider: React.FC = ({ children }) => {
  const [currentCustomer, setCurrentCustomer] = useState<ICustomer | undefined>(
    undefined,
  );

  const saveCustomerToStorage = (
    value: SetStateAction<ICustomer | undefined>,
  ) => {
    if (value) {
      localStorage.setItem(customerKey, JSON.stringify(value));
    }
    return setCurrentCustomer(value);
  };
  const currentCustomerContextValue = useMemo(
    () => ({
      currentCustomer,
      setCurrentCustomer: saveCustomerToStorage,
    }),
    [currentCustomer],
  );

  return (
    <CurrentCustomerContext.Provider value={currentCustomerContextValue}>
      {children}
    </CurrentCustomerContext.Provider>
  );
};
