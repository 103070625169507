import React, { useEffect, useState, useRef } from "react";
import { ContentfulPagesContext } from "Contexts/contentful";
import { useCreateClient } from "Hooks/useCreateClient";
import { Entry, EntryCollection, createClient } from "contentful";
import { PAGE_CONTENT_TYPE } from "Constants/contentful";
import { IPage } from "Components/Pages/MarketingSite/Page/types";
import { PromiseState } from "Constants/promise";
import Loader from "Components/Organisms/Loader";
import { useLocation } from "react-router-dom";
import { formatPath, contentfulArgs } from "./utils";

type IProps = {
  children: React.ReactNode;
};

const MyProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [data, setData] = useState<Entry<IPage>[]>();
  const { client, createContentfulClient } = useCreateClient();
  const [loadingState, setLoadingState] = React.useState<PromiseState | void>();
  const location = useLocation();
  const path = formatPath(location.pathname);
  const refPath = useRef(path);

  useEffect(() => {
    if (!client) {
      createContentfulClient(contentfulArgs, createClient);
    }
  }, [createContentfulClient, client]);

  useEffect(() => {
    if (!client) {
      return;
    }
    const fetchData = async (
      path?: string,
    ): Promise<EntryCollection<IPage>> => {
      const response = await client.getEntries<IPage>({
        content_type: PAGE_CONTENT_TYPE,
        include: 1,
        "fields.path": path,
        limit: 1,
      });
      return response;
    };
    if (!data || refPath.current !== path) {
      refPath.current = path;
      setLoadingState(PromiseState.PENDING);
      fetchData(path)
        .then((res) => {
          setData(res.items);
          setLoadingState(PromiseState.FULFILLED);
          return;
        })
        .catch((e) => {
          setLoadingState(PromiseState.REJECTED);
          console.log(e);
        });
    }
    if (data?.length === 0) {
      setLoadingState(PromiseState.PENDING);
      fetchData("404")
        .then((res) => {
          setData(res.items);
          setLoadingState(PromiseState.FULFILLED);
          return;
        })
        .catch((e) => {
          setLoadingState(PromiseState.REJECTED);
          console.log(e);
        });
    }
  }, [client, data, path]);

  const success = loadingState === PromiseState.FULFILLED;
  const isLoading = loadingState === PromiseState.PENDING;
  const error =
    loadingState === PromiseState.REJECTED ||
    (loadingState === PromiseState.FULFILLED && !data);

  return (
    <ContentfulPagesContext.Provider value={data}>
      {/* <ToastProvider> */}
      {((isLoading && !data) || error) && <Loader error={error} />}
      {success && data && children}
      {/* </ToastProvider> */}
    </ContentfulPagesContext.Provider>
  );
};

export default MyProvider;
