import { HEX } from "Components/Quarks/Colors";

export const color = (type: "error" | "success" | "info") => {
  switch (type) {
    case "error": {
      return HEX.red;
    }
    case "success": {
      return HEX.duck;
    }
    case "info": {
      return HEX.fish;
    }
    default: {
      return HEX.green;
    }
  }
};
