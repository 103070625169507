/* eslint-env node */
export const SPACE = process.env.REACT_APP_CONTENTFUL_SPACE_ID ?? "";
export const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ?? "";
export const ENV = process.env.REACT_APP_CONTENTFUL_ENV ?? "";
export const KUSTOMER_API_KEY = process.env.REACT_APP_KUSTOMER_API_KEY ?? "";
export const KUSTOMER_BRAND_ID = process.env.REACT_APP_KUSTOMER_BRAND_ID ?? "";
export const CAT_HEAVEN_API_KEY =
  process.env.REACT_APP_CAT_HEAVEN_API_KEY ?? "";
export const CAT_HEAVEN_API_HOST =
  process.env.REACT_APP_CAT_HEAVEN_API_HOST ?? "";
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? "";
export const PENDING_ACCOUNT_CHECK_INTERVAL_MS =
  process.env.REACT_APP_PENDING_ACCOUNT_CHECK_INTERVAL_MS ?? "5000";
export const PENDING_UPCOMINGBOX_CHECK_INTERVAL_MS =
  process.env.REACT_APP_PENDING_UPCOMINGBOX_CHECK_INTERVAL_MS ?? "5000";
export const PENDING_ACCOUNT_CHECK_INTERVAL_MAX_MS =
  process.env.REACT_APP_PENDING_ACCOUNT_CHECK_INTERVAL_MAX_MS ?? "120000";
export const BRIGHTBACK_APP_ID = process.env.REACT_APP_BRIGHTBACK_APP_ID ?? "";
