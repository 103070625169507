import React from "react";
import useToast from "Hooks/useToast";
import { StyledToast } from "./styles/toast.scss";
import { color } from "./utils";

const Toast: React.FC = () => {
  const { toast } = useToast();

  return toast ? (
    <StyledToast color={color(toast.type)} type={toast.type} persist={toast.persist} role="status" aria-live="polite">
      {toast.text}
    </StyledToast>
  ) : null;
};

export default Toast;
