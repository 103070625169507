import {
  createClient as contentfulCreateClient,
  CreateClientParams,
} from "contentful";
import React from "react";
import { ContentfulClient } from "Types/contentful";

export const useCreateClient = (): {
  client: ContentfulClient | void;
  createContentfulClient: (
    contentfulArgs: CreateClientParams,
    createClient: typeof contentfulCreateClient,
  ) => void;
  errorCreatingClient?: React.SetStateAction<string>;
} => {
  const [client, setClient] = React.useState<ContentfulClient | void>();
  const [errorCreatingClient, setErrorCreatingClient] =
    React.useState<React.SetStateAction<string>>("");

  const createContentfulClient = (
    contentfulArgs: CreateClientParams,
    createClient: typeof contentfulCreateClient,
  ): void => {
    try {
      const client = createClient(contentfulArgs);
      setClient(client);
    } catch (e: any) {
      setErrorCreatingClient(e);
      console.log(e);
    }
  };

  return { client, createContentfulClient, errorCreatingClient };
};
