import AnalyticsContext from "Contexts/AnalyticsContext";
import React from "react";
import { getSegmentAnalytics, linkGoogleAnalytics } from "@smalls/helpers";

type Props = {
  children: React.ReactNode;
};

const AnalyticsProvider = ({ children }: Props) => {
  const analytics = getSegmentAnalytics((_analytics) => {
    linkGoogleAnalytics();
  });

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
