import React, { useState, useEffect, useCallback } from "react";
import { IToast } from "Components/Atoms/Toast/types";

type IToastContext = {
  toast: IToast | null;
  addToast: ({ text, type, persist }: IToast) => void;
  clearToast: () => void;
};

export const ToastContext = React.createContext<IToastContext>({
  toast: null,
  addToast: () => {},
  clearToast: () => {},
});

export default function ToastProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [toast, setToast] = useState<IToast | null>(null);

  const clearToast = useCallback(() => {
    return setTimeout(() => {
      if (!toast?.persist) setToast(null);
    }, 8000);
  }, [toast]);

  useEffect(() => {
    if (toast) {
      clearToast();
      clearTimeout(clearToast());
    }
  }, [clearToast, toast]);

  const addToast = useCallback(({ text, type = "info", persist = false }: IToast) => {
    setToast({ text, type, persist });
  }, []);

  const contextValue = {
    toast,
    addToast,
    clearToast,
  };

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
    </ToastContext.Provider>
  );
}
