import { SPACE, ACCESS_TOKEN, ENV } from "../config";

export const contentfulArgs = {
  space: SPACE,
  accessToken: ACCESS_TOKEN,
  environment: ENV,
};

export const formatPath = (path: string) =>
  path === "/" ? "/" : path.substring(1).replace(/\/+$/, "");
