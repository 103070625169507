import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { HEX } from "Components/Quarks/Colors";

const animateToast = keyframes`
	0% {
    top: -3rem;
	  opacity: 0;
  }
  8.33%, 75% {
    top: 1.25rem;
	  opacity: 1;
  }
  83.33%, 100% {
    top: -3rem;
	  opacity: 0;
  }
`;

export const StyledToast = styled.div<{ color: string; type: string, persist?: boolean }>`
  background-color: ${(props) => props.color};
  text-align: center;
  color: ${(props) => (props.type === "error" ? HEX.white : HEX.black)};
  padding: 1rem 1.25rem;
  position: fixed;
  top: 1.25rem;
  left: 1.25rem;
  right: 1.25rem;
  width: calc(100% - 2.5rem);
  z-index: 999;
  ${
    (props) => !props.persist && css`
      animation-name: ${animateToast};
      animation-duration: 5000ms;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    `
  }
`;
