import {
  addHeapEventProps,
  flattenExperiments,
  ISegmentPageArgs,
  ISmallsExperiment,
  trackScrollPercent,
  useExperiments
} from "@smalls/helpers";
import { useAnalytics } from "Hooks/useAnalytics";
import { useCurrentCustomer } from "Hooks/useCurrentCustomer";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ICustomer } from "Types/AccountPortal";

let currentLocation = "";
let quarters = 0;

const LocationListener: React.FC = ({ children }) => {
  const location = useLocation();
  const { currentCustomer } = useCurrentCustomer();
  const analytics = useAnalytics();
  const { experiments, isRunningExperimentChecks } = useExperiments();

	const addExperimentDataToHeap = useCallback(() => {
		if (!experiments) return;
		addHeapEventProps(flattenExperiments(experiments));
	}, [experiments]);

  const logPageView = useCallback(() => {
    const pageOptions: Record<string, string | number | ISmallsExperiment[] | undefined> = {};
    const optionalData: ISegmentPageArgs = {
      orderCount: currentCustomer?.ordersCount,
      catCount: currentCustomer?.catsCount || 0,
      customerId: currentCustomer?.id?.toString() || undefined,
      experiments,
    };
    Object.entries(optionalData).forEach(([key, data]) => {
      if (data) {
        pageOptions[key] = data;
      }
    });
    analytics?.page({ ...pageOptions });
  }, [analytics, currentCustomer, experiments]);

  const identifyCustomer = useCallback(() => {
    currentCustomer &&
      analytics?.identify<Partial<ICustomer>>(currentCustomer.id.toString(), {
        firstName: currentCustomer.firstName,
        lastName: currentCustomer.lastName,
        email: currentCustomer.email,
        ordersCount: currentCustomer.ordersCount,
        phone: currentCustomer.phone,
        planId: currentCustomer.planId,
        planBoxId: currentCustomer.planBoxId,
        planBoxSize: currentCustomer.planBoxSize,
        planFrequency: currentCustomer.planFrequency,
        createdAt: currentCustomer.createdAt,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics, currentCustomer?.id]);

  const sendScrollEvent = useCallback(
    (percent: number) => {
      analytics?.track("Scroll Depth", {
        percent,
      });
    },
    [analytics],
  );

  const handleScroll = useCallback(() => {
    trackScrollPercent(quarters, (percent) => {
      sendScrollEvent(percent);
      quarters++;
    });
  }, [sendScrollEvent]);

  useEffect(() => {
    // wait for experiments to be loaded
    if (isRunningExperimentChecks) return;

    // Prevent double pageviews
    if (currentLocation === location.pathname) return;

    currentLocation = location.pathname;
    addExperimentDataToHeap();
    logPageView();
    identifyCustomer();

    // fire scroll depth event on page load
    sendScrollEvent(0);

    window.addEventListener("scroll", handleScroll, { passive: false });
    return () => {
      // Start afresh when URL changes.
      // Run only once.
      if (quarters !== 0) {
        quarters = 0;
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [
    isRunningExperimentChecks,
    location.pathname,
    addExperimentDataToHeap,
    logPageView,
    identifyCustomer,
    handleScroll,
    sendScrollEvent,
  ]);

  return <>{children}</>;
};

export default LocationListener;
